import {
  Input,
  Output,
  OnInit,
  OnChanges,
  OnDestroy,
  Component,
  ViewChild,
  TemplateRef,
  ContentChild,
  HostListener,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { DragNDropHelperService } from '@lc/core';
import Sortable from 'sortablejs';

export interface DragItem {
  disabled?: boolean;
}
/**
 * We use sortableJS to handle our photo drag and drop as it has the functionality we want out-of-the-box
 */
@Component({
  selector: 'lc-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss'],
  standalone: false,
})
export class DragNDropComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @ViewChild('mainContainer')
    mainContainer;

  @ContentChild(TemplateRef)
    itemTemplate: TemplateRef<any>;

  @HostListener('end', ['$event']) onDrop(event) {
    if (event.oldIndex !== event.newIndex) {
      const temp = this.items[event.oldIndex];
      this.items.splice(event.oldIndex, 1);
      this.items.splice(event.newIndex, 0, temp);
      this.reorder.emit(this.items);
    }
  }

  @Input()
    canDrag: boolean = true;

  @Input()
    containerClass: string;

  @Input()
    itemClass: string;

  @Input()
    isManagedPhotos: boolean;

  @Input()
    imagesProcessing: boolean;

  @Input()
    items: any[];

  @Output() readonly reorder = new EventEmitter<any[]>();
  @Output() readonly dragChanged = new EventEmitter<boolean>();

  public gridPlaceholder: boolean = true;

  public sortable: Sortable;

  constructor(public dragndropService: DragNDropHelperService) {}

  ngOnChanges(changes: any) {
    // Ensure the itemClass is assigned to the
    // draggable option for sortable instance.
    if (changes?.itemClass && this.sortable) {
      this.sortable.options.draggable = this.itemClass ? `.${this.itemClass}` : undefined;
    }
    if (changes?.canDrag && this.sortable) {
      this.sortable.options.disabled = !this.canDrag;
    }
  }

  ngOnInit(): void {
    if (this.itemClass === 'manage-photo-grid') {
      this.gridPlaceholder = false;
    }
  }

  ngOnDestroy() {
    this.destroySortableInstance();
  }

  ngAfterViewInit() {
    const el = this.mainContainer?.nativeElement;

    this.destroySortableInstance();
    console.log('drag-n-drop-component is creating a new sortable');
    this.sortable = Sortable.create(el, {
      group: 'shared',
      animation: 250,
      disabled: !this.canDrag || this.isManagedPhotos,
      // Only allow draggable items that match itemClass
      draggable: `.${this.itemClass}`,
      swapClass: 'photo-swap',
      onStart: () => {
        this.dragChanged.emit(true);
        this.dragndropService.updateDragStatus(true);
      },
      onEnd: () => {
        this.dragChanged.emit(false);
        this.dragndropService.updateDragStatus(false);
      },
    });
  }

  destroySortableInstance() {
    if (this.sortable) {
      console.log('drag-n-drop component is destroying the sortable');
      this.sortable.destroy();
      this.sortable = null;
    }
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgxLoadingModule } from 'ngx-loading';

import { CoreModule } from '@lc/core';
import { UILayoutModule, UICoreModule, AvatarModule } from '@lc/ui';

import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ContactInfoComponent } from './layout/contact-info/contact-info.component';
import { BannerComponent } from './layout/banner/banner.component';
import { DragNDropComponent } from './layout/drag-n-drop/drag-n-drop.component';
import { DragNDropCdkComponent } from './layout/drag-n-drop-cdk/drag-n-drop-cdk.component';
import { LayoutWithoutSidenavComponent } from './layout/layout-without-sidenav/layout-without-sidenav.component';

const angularModules = [
  CommonModule, RouterModule, FormsModule,
  ReactiveFormsModule, DragDropModule, MatSnackBarModule,
  MatDividerModule, MatProgressSpinnerModule,
];
const uiModules = [NgxLoadingModule, MatIconModule, MatButtonModule];
const lcModules = [CoreModule, UILayoutModule, UICoreModule, AvatarModule];
const components = [ContactInfoComponent, BannerComponent, DragNDropComponent, DragNDropCdkComponent, LayoutWithoutSidenavComponent];

@NgModule({
  imports: [...angularModules, ...uiModules, ...lcModules, CdkConnectedOverlay, CdkOverlayOrigin],
  declarations: [...components],
  exports: [...components],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedComponentsLayoutModule {}

import {
  Component, OnChanges, SimpleChanges, Input, TemplateRef, ContentChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'lc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: false,
})
export class BannerComponent implements OnChanges {
  @Input()
    message: string;

  @Input()
    level: 'info' | 'warn' | 'error';

  @ContentChild(TemplateRef)
    template: TemplateRef<any>;

  html: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.message) {
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.message);
    }
  }
}

import {
  Component, Input, OnChanges, SimpleChanges, OnDestroy,
} from '@angular/core';
import {
  AppService,
  MarketingOrderContacts,
  MarketingOrderContactService,
  ProfileInfo,
  MarketingOrder,
  ContentKeys,
  ContentfulService,
  ContentValueKeys,
} from '@lc/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'lc-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
  standalone: false,
})
export class ContactInfoComponent implements OnChanges, OnDestroy {
  private genericPhNumSub: Subscription;
  private previousOrder: MarketingOrder;

  @Input() showAgent: boolean = true;
  @Input() showCoLister: boolean = true;
  @Input() showCoordinator: boolean = true;
  @Input() showTeam: boolean = true;
  @Input() isCollapsed: boolean = false;
  @Input() isDetailExpand:boolean = false;
  @Input() marketingOrder: MarketingOrder;

  isLoading: boolean;
  contactInfo$: Observable<MarketingOrderContacts>;

  genericCoordinator = { phoneNumber: '1.833.LIST.CON', email: 'lc@listing-concierge.com' } as ProfileInfo;

  constructor(
    private contactInfoService: MarketingOrderContactService,
    public contentfulService: ContentfulService,
  ) {
    let key = null;

    if (AppService.isDCApp) {
      key = ContentValueKeys.DC;
    } else if (AppService.isAgentApp) {
      key = ContentValueKeys.LC;
    }

    const genericPhNum$ = this.contentfulService.getContent(ContentKeys.CONTACT_INFO, key, '1.833.LIST.CON');
    this.genericPhNumSub = genericPhNum$.subscribe((val: any) => {
      this.genericCoordinator.phoneNumber = val.phoneNumber;
      this.genericCoordinator.email = val.email;
    }, (error) => { throw new Error(error); });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.marketingOrder && this.shouldReload(this.marketingOrder)) {
      this.isLoading = true;
      this.previousOrder = this.marketingOrder;
      this.contactInfo$ = this.contactInfoService.getContactInfo(this.marketingOrder._id)
        .pipe(tap(() => this.isLoading = false));
    }
  }

  /** Executes when the directive is destroeid */
  ngOnDestroy() {
    this.genericPhNumSub?.unsubscribe();
  }

  /**
   * Only reload the contact info if anything related to the agent, colist or team changed
   */
  private shouldReload(updatedMarketingOrder: MarketingOrder) {
    return updatedMarketingOrder && (
      updatedMarketingOrder._id !== this.previousOrder?._id
      || updatedMarketingOrder.agentId !== this.previousOrder?.agentId
      || updatedMarketingOrder.coListAgentId !== this.previousOrder?.coListAgentId
      || updatedMarketingOrder.teamId !== this.previousOrder?.teamId
      || updatedMarketingOrder.listing?.agentType !== this.previousOrder?.listing?.agentType
    );
  }
}
